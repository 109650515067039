export enum Profile {
  Admin = 'admin'
}

export class ProfileType {
  label: string;
  value: Profile;
  static types: ProfileType[] = [
    { value: Profile.Admin, label: 'Administrador' }

  ]
  static mapType(status: Profile): string {
    return this.types.filter(t => t.value == status).map<string>(f => f.label)[0];
  }
}
