import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class SharedUserDataService {
    private subjAvatar = new Subject<AvatarModel>();
    private avatar: AvatarModel;
    constructor() { }

    public getAvatar(): Observable<AvatarModel> {
        return this.subjAvatar.asObservable();
    }

    public updateAvatar(avatar: AvatarModel): void {
        this.avatar = avatar;
        this.subjAvatar.next(avatar);
    }

    public getAvatarValue(): AvatarModel {
        return this.avatar;
    }
}


export interface AvatarModel {
    url: string;
    initials: string;
    fullName: string;
}