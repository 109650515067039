<div class="exception-body notfound">
    <div class="exception-panel">
        <h1>404</h1>
        <h3>no encontrado</h3>
        <p>La página que buscas no existe</p>
        <button title="Volver al dashboard" type="button" pButton label="Volver al dashboard"
            [routerLink]="['/']"></button>
    </div>
    <div class="exception-footer">
        <img title="logo" alt="logo"
            [src]="'assets/layout/images/iformacion-' + (app.colorScheme==='light' ? 'dark' : 'light' ) + '.png'"
            class=" exception-appname" />
    </div>
</div>