import { getUserDefaultProfile } from "./functions";

//welcome
export function welcomeStorage(): boolean {
    const welcomeValue = localStorage.getItem('welcome');
    let isWelcome = welcomeValue != null ? JSON.parse(welcomeValue) : false;
    return isWelcome;
}
export function setWelcomeStorage(welcome: boolean) {
    if (welcome)
        localStorage.setItem('welcome', JSON.stringify(welcome));
    else
        localStorage.removeItem('welcome');
}

//profile
export function getProfileStorage(): string {
    let storegeProfile = localStorage.getItem("profile");
    return storegeProfile;
}
export function setProfileStorage(profile) {
    localStorage.setItem("profile", profile.toString());
}

export function setDefaultProfileStorage(claims) {
    const profile = getUserDefaultProfile(claims);
    setProfileStorage(profile)
}

//user
export function setUserStorage(userData: any) {
    if (userData)
        localStorage.setItem('user', JSON.stringify(userData));
    else
        localStorage.setItem('user', 'null');
}
export function getUserStorage(): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user;
}

//
export function removeUserStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('welcome');
    localStorage.removeItem('profile');
}