import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { Permission } from './core/enums/permissions';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canLoad: [AuthGuard],
    data: { redirectTo: '/dashboard' },
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canLoad: [AuthGuard],
    data: { redirectTo: '/dashboard' },
  },
  {
    path: 'dashboard',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/dashboard/dashboard.module')
        .then(m => m.DashboardModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'anomalies',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/anomalies/anomalies.module')
        .then(m => m.AnomaliesModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'plants',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/plants/plants.module')
        .then(m => m.PlantsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    component: AppMainComponent,
    canLoad: [AuthGuard],
    data: {
      permissions: {
        only: [Permission.Admin],
        redirectTo: '/access'
      }
    },
    loadChildren: () => import('src/app/pages/users/users.module')
      .then(m => m.UsersModule)
  },
  {
    path: 'logs',
    component: AppMainComponent,
    canLoad: [AuthGuard],
    data: {
      permissions: {
        only: [Permission.Admin],
        redirectTo: '/access'
      }
    },
    loadChildren: () => import('src/app/pages/app-usage/app-usage.module')
      .then(m => m.AppUsageModule)
  },
  {
    path: 'versions',
    component: AppMainComponent,
    loadChildren: () =>
      import('src/app/pages/versions/versions.module')
        .then(m => m.VersionsModule),
    canLoad: [AuthGuard],
    data: {
      permissions: {
        only: [Permission.Admin],
        redirectTo: '/access'
      }
    }
  },
  {
    path: 'error',
    component: AppErrorComponent
  },
  { path: 'access', component: AppAccessdeniedComponent },
  { path: 'notfound', component: AppNotfoundComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
