// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  api: 'https://dev.api.aerotools.es',
  firebase: {
    apiKey: "AIzaSyDPNjaM0t0eNNRWhZDqtagVmey0cC0PbuI",
    authDomain: "atom-dev-dd15e.firebaseapp.com",
    projectId: "atom-dev-dd15e",
    storageBucket: "atom-dev-dd15e.appspot.com",
    messagingSenderId: "787401711332",
    appId: "1:787401711332:web:107fd291c00a56c34ed6f1"
  },
  useEmulators: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
