import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getUserRoles } from 'src/app/core/functions';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedUserDataService } from 'src/app/shared/shared-user-data.service';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/core/enums/permissions';
import { User } from 'firebase/auth';
import { SharedSytleService } from 'src/app/shared/shared-style.service';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent {

    user: User;
    avatar: string;
    initials: string;
    displayName: string;
    isAdmin: boolean;
    logo: string;
    private subsAvatar: Subscription;

    constructor(public app: AppComponent,
        public appMain: AppMainComponent,
        public afAuth: AngularFireAuth,
        public authService: AuthService,
        public sharedUserDataService: SharedUserDataService,
        public sharedSytleService: SharedSytleService) {
        this.initAvatar();
    }

    initAvatar() {
        const avatar = this.sharedUserDataService.getAvatarValue();
        if (avatar) {
            this.avatar = avatar.url;
            this.initials = avatar.initials;
            this.displayName = avatar.fullName;
        }
    }

    ngOnDestroy(): void {
        this.subsAvatar?.unsubscribe();
    }

    ngOnInit(): void {
        this.logo = this.sharedSytleService.logo;
        this.afAuth.idTokenResult.subscribe((user) => {
            if (user) {
                this.isAdmin = getUserRoles(user.claims).includes(Permission.Admin);
            }
        });
        this.subsAvatar = this.sharedUserDataService.getAvatar()
            .subscribe(avatar => {
                this.avatar = avatar.url;
                this.initials = avatar.initials;
                this.displayName = avatar.fullName;
            })

    }

    logout() {
        this.authService.SignOut();
    }
}
