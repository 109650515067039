import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable, takeWhile } from "rxjs";
import { HttpParamsBuilder } from "src/app/shared/http-params.builder";
import { PaginatedResult } from "src/app/shared/paginated-result";
import { environment } from "src/environments/environment";
import { AppUsageEntry } from "./app-usage";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { getUserRoles } from "src/app/core/functions";
import { Permission } from "src/app/core/enums/permissions";

@Injectable({
    providedIn: 'root'
})
export class AppUsageService {
    usageSent: boolean;

    constructor(
        public afAuth: AngularFireAuth,
        private http: HttpClient) { }

    private buildUrl(): string {
        return environment.api + '/usage';
    }

    logAppUsage() {
        let observable$ = this.afAuth.idTokenResult;
        observable$.pipe(takeWhile(() => !this.usageSent))
            .subscribe({
                next: (user: any) => {
                    if (user) {
                        if (this.checkUser(user))
                            this.http.post<any>(this.buildUrl(), {})
                                .subscribe({
                                    next: () => {
                                        this.usageSent = true;
                                    }
                                });
                    }
                }
            })
    }
    private checkUser(user): boolean {
        let roles = getUserRoles(user.claims);
        if (roles.includes(Permission.Admin)) {
            return true;
        }
        else return true;
    }

    getLogs(event: LazyLoadEvent, startDate: string, endDate: string)
        : Observable<PaginatedResult<AppUsageEntry>> {

        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let params = HttpParamsBuilder.fromLazyEvent(event);
        params = params
            .append('from', startDate)
            .append('to', endDate)
            .append('timeZone', timeZone)

        return this.http.get<PaginatedResult<AppUsageEntry>>(this.buildUrl(),
            {
                params: params
            });
    }

    getLogsReport(event: LazyLoadEvent, startDate: string, endDate: string, format: string) {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        params = params
            .append('from', startDate)
            .append('to', endDate)
            .append('format', format);

        return this.http.get<any>(this.buildUrl(),
            {
                params: params,
                responseType: 'blob' as 'json'
            })
    }
}

