import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Subscription } from "rxjs";
import { Permission } from "src/app/core/enums/permissions";
import { getUserRoles } from "src/app/core/functions";
import { setWelcomeStorage, welcomeStorage } from "src/app/core/functions-localStorage";

@Component({
    templateUrl: './welcome.component.html',
    selector: 'app-welcome'
})

export class WelcomeComponent implements OnInit {
    welcomeDialog: boolean;
    isAdmin: boolean;
    loadingSpinner: boolean = true;
    private subsAfAuth: Subscription;
    constructor(public afAuth: AngularFireAuth,
    ) {
    }

    ngOnDestroy(): void {
        this.subsAfAuth?.unsubscribe();
    }
    ngOnInit(): void {
        this.subsAfAuth?.unsubscribe();
        this.subsAfAuth = this.afAuth.idTokenResult.subscribe(user => {
            if (user) {
                let roles = getUserRoles(user.claims);
                this.isAdmin = roles.includes(Permission.Admin);
                this.welcomeDialog = !this.isAdmin && welcomeStorage();
            }
            else
                this.welcomeDialog = false;
        });
    }

    loadedStatus(event) {
        this.loadingSpinner = false;
    }
    statusChange(event) {
        setWelcomeStorage(false);
        this.cancel();
    }

    cancel() {
        setWelcomeStorage(false);
        this.welcomeDialog = false;
    }
}