import { HttpParams } from "@angular/common/http";
import { LazyLoadEvent } from "primeng/api";

export class HttpParamsBuilder {
  public static fromLazyEvent(e: LazyLoadEvent): HttpParams {
    let params = new HttpParams()
      .set("searchTerm", e.globalFilter ? e.globalFilter : '')
      .set("orderBy", e.sortField ? e.sortField : 'id')
      .set("order", this.mapOrder(e.sortOrder))
      .set("index", (e.first / e.rows + 1))
      .set("size", e.rows ? e.rows : 10)


    if (e.filters) {
      Object.keys(e.filters).forEach(function (key) {
        if (key != "global") {
          params = params.set(key, e.filters[key].value);
        }
      });
    }

    return params;
  }

  private static mapOrder(order: number): OrderBy {
    return order > 0 ? OrderBy.Asc : OrderBy.Desc;
  }

  public static fromIds(ids: string[]): HttpParams {
    let params: HttpParams = new HttpParams();
    for (let i in ids) {
      params = params.append("ids", ids[i]);
    }
    return params;
  }


  public static mergeParams(params1: HttpParams, params2: HttpParams): HttpParams {

    // Extraer los parámetros en objetos JavaScript
    let params1Aux = params1.keys().reduce((obj, key) => {
      obj[key] = params1.getAll(key);
      return obj;
    }, {});

    let param2Aux = params2.keys().reduce((obj, key) => {
      obj[key] = params2.getAll(key);
      return obj;
    }, {});

    // Combinar los objetos JavaScript
    let combinedParams = { ...params1Aux, ...param2Aux };

    // Crear un nuevo objeto HttpParams a partir del objeto combinado
    let mergedParams = new HttpParams({ fromObject: combinedParams });

    return mergedParams;
  }
}
enum OrderBy {
  Asc = "asc",
  Desc = "desc"
}
