<div class="exception-body error">
    <div class="exception-panel">
        <h1>ERROR</h1>
        <h3>algo ha ido mal</h3>
        <button type="button" pButton label="Volver al inicio" [routerLink]="['/']"></button>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
            class="exception-appname" />
    </div>
</div>