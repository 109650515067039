import { FormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { User } from "firebase/auth";
import * as moment from "moment";
import { Permission } from "./enums/permissions";
import { Profile } from "./enums/profile";
import { getProfileStorage } from "./functions-localStorage";

export function validateAllFormFields(formGroup: UntypedFormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      validateAllFormFields(control);
    } else if (control instanceof FormArray) {
      control.controls.forEach(item => {
        if (item instanceof UntypedFormControl) {
          item.markAsTouched({ onlySelf: true });
          item.markAsDirty({ onlySelf: true });
        }
        else {
          let itemGroup: UntypedFormGroup = item as UntypedFormGroup;
          validateAllFormFields(itemGroup);
        }
      });
    }
  });
}


/******** Fechas ***********/

export function toDateOnlyString(date: Date) {
  if (!date)
    return null;
  else
    return `${date.getFullYear()}-${returnMonth()}-${returnDay()}`;

  function returnDay() {
    let d = (date.getDate() < 10) ? "0" + (date.getDate()) : date.getDate();
    return d;
  }
  function returnMonth() {
    let m = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    return m;
  }
}

export function formatDate(inputDate: Date) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date
    .toString()
    .padStart(2, '0');

  month = month
    .toString()
    .padStart(2, '0');

  return `${date}/${month}/${year}`;
}

export function checkStringDate(date: string, dateFormat: string): boolean {

  let newDate = moment(date, dateFormat);
  return newDate.isValid() && (moment(newDate).format(dateFormat) == date);

}
export function stringToDate(date: string, dateFormat: string): Date {

  let newDate = moment(date, dateFormat);
  return newDate.startOf("day").toDate();

}
export function isWeekEnd(date: Date): boolean {
  return (date.getDay() == 0 || date.getDay() == 6);
}


export function getDateDefaultOffset(dateStr: string): string {
  const originalDate = new Date(dateStr);
  const formattedDate = new Date(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate(),
    originalDate.getHours(), originalDate.getMinutes(), originalDate.getSeconds());
  return formattedDate.toString();
}

export function getOffsetDateStr(dateStr: string): string {
  const fecha = new Date(dateStr);
  const offsetMinutes = fecha.getTimezoneOffset();
  const hours = Math.abs(Math.floor(offsetMinutes / 60));
  const minutes = Math.abs(offsetMinutes % 60);
  const sign = offsetMinutes > 0 ? '-' : '+';
  return `${sign}${padZero(hours)}:${padZero(minutes)}`;
}

export function getDateWithOffset(dateStr: string, offsetStr): Date {
  const originalDate = new Date(dateStr);
  const offsetInMinutes = offsetStrToMinutes(offsetStr);
  const adjustedTimestamp = originalDate.getTime() + offsetInMinutes * 60 * 1000;
  const adjustedDate = new Date(adjustedTimestamp);
  return adjustedDate;
}
function offsetStrToMinutes(offsetStr: string): number {
  const sign = offsetStr.charAt(0) === '+' ? 1 : -1;
  const parts = offsetStr.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  // Calcular el offset en minutos
  const offsetInMinutes = sign * (hours * 60 + minutes);
  return offsetInMinutes
}

function padZero(number: number): string {
  return number < 10 ? '0' + number : number.toString();
}


//Obtiene la hora local
export function getLocalDate(date) {

  const now = new Date();
  var tempDate = new Date(date);
  return new Date(tempDate.getTime() - (now.getTimezoneOffset() * 60000));
}

/******** Tiempo ***********/
export function checkStringTime(time: string): boolean {
  let result = false;
  if (time && time.indexOf(":") > -1) {
    let timeParts = time.replace(/\s/g, "").split(":");

    if (timeParts.length < 3)
      return false;

    let hours = Number(timeParts[0]);
    let minutes = Number(timeParts[1]);
    let seconds = Number(timeParts[2]);
    result = !isNaN(hours)
      && !isNaN(minutes) && minutes <= 59
      && !isNaN(seconds) && seconds <= 59;
  }

  return result;
}

export function formatStringTime(time: string): string {
  let result = null;
  if (time && time.indexOf(":") > -1) {
    let timeParts = time.replace(/\s/g, "").split(":");
    let hasError = timeParts.findIndex(f => isNaN(Number(f))) >= 0;

    if (!hasError) {
      let hours = timeParts.length == 3 ? normalizeTimePart(timeParts[0], true) : "00";
      let minutes = timeParts.length == 3 ? normalizeTimePart(timeParts[1]) : normalizeTimePart(timeParts[0]);
      let seconds = timeParts.length == 3 ? normalizeTimePart(timeParts[2]) : normalizeTimePart(timeParts[1]);
      result = `${hours}:${minutes}:${seconds}`;
    }
  }

  return result;
}

export function normalizeTimePart(time: string, overflow: boolean = false) {

  if (time.length > 2) {
    return !overflow ? time.substring(0, 2) : time;
  }
  else if (time.length == 2) {
    return time;
  }
  else if (time.length == 1) {
    return `0${time}`;
  }
  else {
    return `00`;
  }

}

export function stringTimeToMilliseconds(time: string): number {

  if (checkStringTime(time)) {
    let timeParts = time.replace(/\s/g, "").split(":");
    let hours = Number(timeParts[0]);
    let minutes = Number(timeParts[1]);
    let seconds = Number(timeParts[2]);

    return ((hours * 3600000) + (minutes * 60000) + (seconds * 1000));
  }
  return 0
}

export function millisecondsToStringHhMhSs(milliseconds) {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor(((milliseconds % 360000) % 60000) / 1000);
  return `${normalizeTimePart(hours.toString(), true)}:${normalizeTimePart(minutes.toString())}:${normalizeTimePart(seconds.toString())}`;
};

export function DateToStringHHMMSS(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${normalizeTimePart(hours.toString(), true)}:${normalizeTimePart(minutes.toString())}:${normalizeTimePart(seconds.toString())}`;
};


/***************** */
export function calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/******************* */

export function removeFileExtension(fileName: string) {
  let indexExtension = fileName.lastIndexOf('.');
  if (indexExtension === -1) return fileName;
  else return fileName.substring(0, indexExtension);
}


/********** Usuario *********/
export function getUserRoles(claims): Permission[] {
  let permissions = [];
  if (claims) {
    let role = claims.role;
    switch (role) {
      case Permission.Admin:
        permissions.push(Permission.Admin);
        break;
    }
  }

  return permissions;
}
export function getUserAvatar(user): string {
  let avatar = null;
  if (user.photoURL) {
    avatar = user.photoURL;
  }
  else if (user.claims && user.claims.picture) {
    avatar = user.claims.picture;
  }
  return avatar;
}
export function getDefaultProfile(claims): Profile {
  let profile;
  if (claims) {
    let role = claims.role;
    switch (role) {
      case Permission.Admin:
        profile = Profile.Admin;
        break;
      default:
        profile = Profile.Admin;
        break;
    }
  }

  return profile;
}
export function getUserInitials(user: User) {
  let name = "";
  if (user.displayName) {
    name = user.displayName;
  }
  else {
    name = user.email;
  }
  const initials = getInitials(name);
  return initials;
}
export function getDisplayName(user: User) {
  if (user.displayName) {
    return user.displayName;
  }
  else {
    return user.email;
  }
}
export function getInitials(name: string): string {
  let initials = "";
  let initialsArray = name.split(' ');
  if (initialsArray.length > 1) {
    initials += initialsArray[0][0] + initialsArray[1][0];
  }
  else {
    initials += initialsArray[0][0] + initialsArray[0][1];
  }
  return initials;
}
export function getFullName(name: string, lastName: string): string {
  let fullName = name ? name : '';

  if (lastName) {
    fullName += (fullName ? " " : "") + lastName;
  }
  return fullName;
}

export function getUserDefaultProfile(claims): Profile {
  let storegeProfile = getProfileStorage();
  let profile;
  if (claims) {
    let role = claims.role;
    switch (role) {
      case Permission.Admin:
        profile = Profile.Admin;
        profile = Profile.Admin;
        break;
    }
  }

  return profile;
}

/********** Objetos ********* */
export function deepCopy(oldObj: any) {
  var newObj = oldObj;
  if (oldObj && typeof oldObj === "object") {
    if (oldObj instanceof Date) {
      return new Date(oldObj.getTime());
    }
    newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
    for (var i in oldObj) {
      newObj[i] = deepCopy(oldObj[i]);
    }
  }
  return newObj;
}

/***************** */
export function normalizeName(name: string) {
  return name ? name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "") : name;
}

/******* Array ********/
export function arrayMove(array, oldIndex, newIndex) {
  if (newIndex >= array.length) {
    var k = newIndex - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
};

export function insertArrayAt(array, index, arrayToInsert) {
  Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
  return array;
}

export function arrayChunk(array, chunkSize) {
  const result = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, []);

  return result;
}

export function formatErrorMessage(error, defaultMessage: string): string {
  console.log("error", error.error.errors);
  return (error?.error?.errors ? JSON.stringify(error.error.errors) : defaultMessage);
}

