import { Injectable } from '@angular/core';
import { SharedSytleService } from './shared/shared-style.service';



@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    constructor(private sharedSytleService: SharedSytleService) {
    }

    init() {
        var hostname = window.location.hostname;
        this.loadData(hostname);
    }

    loadData(hostname: string) {
        this.sharedSytleService.setDefaultValues();
    }
}
