import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ProgressBarModule } from "primeng/progressbar";
import { TooltipModule } from "primeng/tooltip";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from "primeng/dialog";
import { WelcomeComponent } from "./welcome.component";
@NgModule({
  declarations: [
    WelcomeComponent
  ],
  exports: [WelcomeComponent],
  imports: [
    CommonModule,
    TooltipModule,
    ProgressSpinnerModule,
    FormsModule,
    DialogModule,
    ProgressBarModule,
  ],
  providers: [
  ]
})

export class WelcomeModule { }
